module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://plannc.matomo.cloud","siteUrl":"https://plan.nc","dev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Plan.nc","short_name":"Plan.nc","start_url":"/","background_color":"#9DCB21","theme_color":"#7CAF41","display":"standalone","icon":"/opt/build/repo/src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2d4ea8bef710dcc8560220eb8901cf2d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
