import colors from "./colors"
import buttons from "./buttons"
import variants from "./variants"
import forms from "./forms"
const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    body: "'Ubuntu', sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    "12rem",
    "14rem",
    "16rem",
    "20rem",
    "22rem",
    "24rem",
    "28rem",
    "32rem",
    "48rem",
    "64rem",
    "96rem",
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 128, 256, 512],
  sizes: {
    avatar: 48,
  },
  radii: {
    square: 0,
    default: 4,
    circle: 99999,
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)",
    headerButton: "0 2px 4px 0 rgba(130, 130, 130, 0.5)",
    player: "0px 1px 11px rgb(0 0 0 / 33%)",
    tooltip: "0px 4px 16px rgb(7 18 37 / 20%)",
  },
  text: {
    heading: {
      fontFamily: "inherit",
      fontWeight: "900",
      fontSize: [3, 4, 5],
    },
    h2: {
      variant: "heading",
      fontSize: [6],
    },
    h3: {
      variant: "heading",
      fontSize: [2, 3],
    },
    display: {
      fontFamily: "Work Sans",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit",
    },
    tip: {
      fontSize: ".7em",
      color: "gray",
    },
  },
  variants: variants,
  buttons: buttons,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
  },
  breakpoints: ["460px", "768px", "920px", "1200px", "1440px"],
  gutter: 30,
}
export default theme
