import styled, { createGlobalStyle } from "styled-components"
import "./fonts/webfonts.css"
import {
  width,
  height,
  color,
  space,
  boxShadow,
  borderRadius,
} from "styled-system"
import { themeGet } from "@styled-system/theme-get"
// import { normalize } from 'polished';
/* Wiltech global style */
const GlobalStyle = createGlobalStyle`
html{
  font-size:1px;
}
  body {
      font-family: ${themeGet("fonts.body")};
      background-color: rgba(47, 72, 88, 0.1);
    color: ${themeGet("colors.text")};
    font-size:${themeGet("fontSizes.2")};
    margin: 0;
  }
  *{
    font-family: ${themeGet("fonts.body")};
    &:focus {
    outline: none;
    outline-color: transparent;
    outline-style: auto;
    outline-width: 0px;
}
a{
  color:inherit;
  text-decoration:inherit;
  font-size:inherit;
  font-weight:inherit;
}
  }
  img{
    max-width:100%;
  }
  .mapboxgl-ctrl-attrib,.mapboxgl-ctrl-geolocate{
    display:none !important;
  }
.mapboxgl-control-container {
    /* display: none; */

    .mapboxgl-ctrl-scale {
      background-color: transparent;
      font-size: 11px;
      border: none;
      padding: 0 5px;
      color: ${themeGet("colors.text")};
      text-align: center;
      box-sizing: border-box;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        height: 10px;
        top: -15px;
        right: 6px;
        left: 6px;
        border: 2px solid ${themeGet("colors.text")};
        border-top: none;
      }
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi){
    #result-wrapper{
      font-size:.75em;
      h1{
        font-size:18px;
      }
    }
    html{
      // font-size:.75px;

    }
    .header-text {
      // font-size:20rem;
  }
  }
`

/* Wiltech wrapper style */
const SiteWrapper = styled.div`
  /* Style system supported prop */
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`

GlobalStyle.displayName = "GlobalStyle"
SiteWrapper.displayName = "SiteWrapper"

export { GlobalStyle, SiteWrapper }
