import {
  CURRENT_NODE_RESET,
  SET_CURRENT_NODE,
  SET_FOCUSED_NODE,
  SET_CURRENT_NODE_ROUTING,
} from "../actions/currentNodeActions"

const initialState = {
  item: false,
  focusedItem: false,
  hasCurrent: false,
  loading: false,
  fromMap: false,
  routing: false,
}
export default function resultReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENT_NODE_RESET:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return initialState

    case SET_FOCUSED_NODE:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        focusedItem: action.payload.data,
        fromMap: action.payload.fromMap,
      }

    case SET_CURRENT_NODE_ROUTING:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        routing: action.payload.data,
      }
    case SET_CURRENT_NODE:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        hasCurrent: true,
        focusedItem: false,
        item: action.payload.data,
        routing: false,
      }
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
