import { store } from "../store"

export const USER_LOGIN = "USER_LOGIN"
export const USER_SUBSCRIBE = "USER_SUBSCRIBE"
export const USER_UPDATE = "USER_UPDATE"
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_TOKEN = "USER_TOKEN"
export const USER_RDV_LIST = "USER_RDV_LIST"
export const USER_FAVORIS_LIST = "USER_FAVORIS_LIST"
export const USER_RESET = "USER_RESET"
export const USER_GET = "USER_GET"
export const USER_CANCEL = "USER_CANCEL"
export const USER_SESSION = "USER_SESSION"
export const USER_PASS_RESET = "USER_PASS_RESET"
export const USER_CREATE_CODE = "USER_CREATE_CODE"
export const USER_CHECK_CODE = "USER_CHECK_CODE"

const setCookie = (cname, cvalue, exdays) => {
  if (document) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }
}

const removeCookie = cname => {
  if (document) {
    document.cookie = cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  }
}

const loginResponse = data => {
  if (process.env.NODE_ENV === "development") {
    if (data?.sessid && data?.session_name) {
      setCookie(data.session_name, data.sessid, 2)
    }
  }

  const user = { ...data.user }
  delete user.plan_app_cgu
  data.user = parseInt(user.uid) === 0 ? false : user
  return data
}

const logoutResponse = data => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    removeCookie(currentUser.session_name)
  }
  if (process.env.NODE_ENV === "development") {
    console.log(data)
  }
  return data
}

export const currentSession = () => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_SESSION,
    payload: {
      request: {
        method: "post",
        url: "system/connect",
        headers: {
          "X-CSRF-Token": currentUser.token,
        },
        transformResponse: [loginResponse],
      },
    },
  }
}

export const getUser = uid => {
  return {
    type: USER_GET,
    payload: {
      request: {
        method: "get",
        url: "user/" + uid,
      },
    },
  }
}

export const getToken = withCredentials => {
  withCredentials = withCredentials || false
  return {
    type: USER_TOKEN,
    payload: {
      client: "Api",
      request: {
        method: "post",
        withCredentials:
          process.env.NODE_ENV !== "development" ? withCredentials : false,
        responseType: "text",
        url: "services/session/token",
      },
    },
  }
}

export const user_pass_reset = (uid, timestamp, newpass) => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_PASS_RESET,
    payload: {
      request: {
        method: "post",
        url: "user/change_password_reset",
        data: {
          uid: uid,
          timestamp: timestamp,
          newpass: newpass,
        },
        headers: {
          "X-CSRF-Token": currentUser.token,
        },
        keeploggedin: true,
        transformResponse: [loginResponse],
      },
    },
  }
}

export const user_reset = (uid, timestamp, hashed_pass) => {
  return {
    type: USER_RESET,
    payload: {
      request: {
        method: "post",
        url: "user/user_pass_reset",
        data: {
          uid: uid,
          timestamp: timestamp,
          hashed_pass: hashed_pass,
        },
        keeploggedin: true,
        transformResponse: [loginResponse],
      },
    },
  }
}

export const login = (user, keeploggedin) => {
  keeploggedin = keeploggedin || false
  return {
    type: USER_LOGIN,
    payload: {
      request: {
        method: "post",
        url: "user/login",
        withCredentials: false,
        data: user,
        keeploggedin: keeploggedin,
        transformResponse: [loginResponse],
      },
    },
  }
}

export const subscribe = (user, autologin) => {
  autologin = autologin || false
  if (autologin) {
    return {
      types: [
        USER_SUBSCRIBE,
        USER_LOGIN + "_SUCCESS",
        USER_SUBSCRIBE + "_FAIL",
      ],
      payload: {
        request: {
          method: "post",
          url: "user/register",
          data: user,
          keeploggedin: true,
          transformResponse: [loginResponse],
        },
      },
    }
  }
  return {
    type: USER_SUBSCRIBE,
    payload: {
      request: {
        method: "post",
        url: "user/register",
        data: user,
      },
    },
  }
}

export const getRdvList = () => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    return {
      type: USER_RDV_LIST,
      payload: {
        request: {
          url: "views/rendez_vous_user?display_id=services_1",
          headers: {
            "X-CSRF-Token": currentUser.token,
          },
        },
      },
    }
  }
}

export const getFavoris = () => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    return {
      type: USER_FAVORIS_LIST,
      payload: {
        request: {
          url: "views/favoris_user?display_id=services_1",
          headers: {
            "X-CSRF-Token": currentUser.token,
          },
        },
      },
    }
  }
}

export const cancelUser = uid => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_CANCEL,
    payload: {
      request: {
        method: "post",
        url: "user/" + uid + "/cancel",
        headers: {
          "X-CSRF-Token": currentUser.token,
        },
      },
    },
  }
}

export const update = (user, uid) => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_UPDATE,
    payload: {
      request: {
        method: "put",
        url: "user/" + uid,
        data: user,
        transformResponse: [
          data => {
            if (data.field_ville_ref) {
              data.field_ville_ref.und = [data.field_ville_ref.und]
            }
            return data
          },
        ],
        headers: {
          "X-CSRF-Token": currentUser.token,
          // "cookie": currentUser.session_name + "=" + currentUser.sessid
        },
      },
    },
  }
}

export const reset_password = user => {
  return {
    type: USER_RESET_PASSWORD,
    payload: {
      request: {
        method: "post",
        url: "user/request_new_password.json",
        data: user,
        transformResponse: [
          function(data) {
            if (process.env.NODE_ENV === "development") {
              console.log(data)
            }
            return data
          },
        ],
      },
    },
  }
}

export const removeCurrentUser = () => {
  return {
    type: USER_LOGOUT + "_SUCCESS",
  }
}

export const logout = () => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    return {
      type: USER_LOGOUT,
      payload: {
        request: {
          method: "post",
          url: "user/logout",
          transformResponse: [logoutResponse],
          headers: {
            "X-CSRF-Token": currentUser.token,
          },
        },
      },
    }
  }
}

export const user_create_code = uid => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_CREATE_CODE,
    payload: {
      request: {
        method: "post",
        url: "phone-validation/create",
        data: {
          uid: uid,
        },
        headers: {
          "X-CSRF-Token": currentUser.token,
        },
      },
    },
  }
}

export const user_check_code = (uid, code) => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_CHECK_CODE,
    payload: {
      request: {
        method: "post",
        url: "phone-validation/check",
        data: {
          uid: uid,
          code: code,
        },
        headers: {
          "X-CSRF-Token": currentUser.token,
        },
      },
    },
  }
}
