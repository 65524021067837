import {
  SET_IS_MAP,
  TOGGLE_IS_MAP,
  SET_LOCATION,
  TOGGLE_MAP_DRAWER,
  SET_SHOW_FILTERS,
  SET_NEW_SESSION,
  SET_MAP_DRAWER_LEVEL,
  SET_READY_TO_USE,
  SET_FIRST_LOCATION,
  TOGGLE_AROUNDME,
  SET_USER_POS,
  SET_IS_MOBILE,
} from "../actions/globalAction"
import { SOLR_SET_WORD } from "../actions/SolrActions"
// import { PURGE } from 'redux-persist';

const initialState = {
  isMap: false,
  location: false,
  mapDrawerOpen: true,
  mapDrawerLevel: 1,
  showFilters: false,
  newSession: true,
  ready: false,
  searchAround: false,
  userPosition: false,
  userPositionAround: false,
  isMobile: false,
  hasFirstLocation: false,
}

export default function resultReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      }
    case SET_READY_TO_USE:
      return {
        ...state,
        ready: action.ready,
      }

    case SET_FIRST_LOCATION:
      return {
        ...state,
        hasFirstLocation: action.hasFirstLocation,
      }
    case SOLR_SET_WORD:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        searchAround: false,
      }
    case TOGGLE_IS_MAP:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        isMap: !state.isMap,
        mapDrawerOpen: true,
      }
    case SET_USER_POS:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      if (state.searchAround || !state.userPositionAround) {
        return {
          ...state,
          userPosition: action.position,
          userPositionAround: action.position,
        }
      }
      return {
        ...state,
        userPosition: action.position,
      }
    case TOGGLE_AROUNDME:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        searchAround: !state.searchAround,
      }
    case TOGGLE_MAP_DRAWER:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        mapDrawerOpen: !state.mapDrawerOpen,
        mapDrawerLevel: state.mapDrawerOpen ? 0 : 1,
      }
    case SET_MAP_DRAWER_LEVEL:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        mapDrawerLevel: action.level,
        mapDrawerOpen: action.isOpen,
      }

    case SET_IS_MAP:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        isMap: action.isMap,
        mapDrawerOpen: true,
      }
    case SET_NEW_SESSION:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        newSession: action.newSession,
      }
    case SET_LOCATION:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        location: action.location,
        isMap: action.isMap,
        mapDrawerOpen: action.mapDrawerOpen,
      }
    case SET_SHOW_FILTERS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        showFilters: action.showFilters,
      }
    // case PURGE:
    //   return state;// Return the initial state of this reducer to 'reset' the app
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
