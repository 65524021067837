const baseItem = {
  color: "text",
  borderColor: "gray",
  borderWidth: "1px",
  borderRadius: "default",
  "::placeholder": {
    color: "gray",
  },
  backgroundColor: "white",
}

const forms = {
  searchBar: {
    color: "text",
    px: "16px",
    py: ["8px", "8px", "14px"],
    borderWidth: 0,
    "::placeholder": {
      color: "text",
    },
    backgroundColor: "white",
  },
  input: baseItem,
  select: baseItem,
  textarea: baseItem,
  text: { color: "text" },
  label: { pb: 2 },
  radio: {},
  checkbox: {},
}

export default forms
