import isEmpty from "lodash/isEmpty"
import isArray from "lodash/isArray"
import forEach from "lodash/forEach"
import uniq from "lodash/uniq"
import { getPath } from "../../utils"
import { store } from "../store"
var Qs = require("qs")
export const FETCH_SOLR_LOAD = "FETCH_SOLR_LOAD"
export const FETCH_SOLR_LOAD_SUCCESS = "FETCH_SOLR_LOAD_SUCCESS"
export const FETCH_SOLR_LOAD_ERROR = "FETCH_SOLR_LOAD_ERROR"
export const SOLR_SET_WORD = "SOLR_SET_WORD"
export const SOLR_RESET = "SOLR_RESET"
export const SOLR_RESET_FILTERS = "SOLR_RESET_FILTERS"
export const SOLR_RESET_ITEMS = "SOLR_RESET_ITEMS"
export const SOLR_SET_LAST_LIST_PATH = "SOLR_SET_LAST_LIST_PATH"

const defaultParams = {
  qf: [
    "tm_field_mots_cles^1.0",
    "tm_field_telephone_standard$number^1.0",
    "tm_field_telephone_portable$number^1.0",
    "tm_field_numero_de_fax$number^1.0",
    "tm_field_tel_poste1$number^1.0",
    "tm_field_tel_poste2$number^1.0",
    "tm_field_tel_poste3$number^1.0",
    "tm_field_gsm_1$number^1.0",
    "tm_field_gsm_2$number^1.0",
    "tm_field_gsm_3$number^1.0",
    // "tm_field_marques_deposees^1.0",
    // "tm_field_succursales^1.0",
    "tm_search_api_aggregation_3^2.0",
    "tm_field_nom_ville$title^1.0",
    "tm_field_reference_rue$title^1.0",
    "tm_field_quartier$title^1.0",
    // "tm_field_champ_photo$alt^1.0",
    "tm_field_categories$name^1.0",
    "tm_field_categories$parents_all$name^1.0",
    "tm_field_marques$value^1.0",
    // "tm_field_activites$value^1.0",
    // "sm_field_marques_ref$name^1.0"
  ],
  fq: [
    'bs_status:"true"',
    '-(fs_field_position$lat:"0")',
    // '((ss_type:"les_baies") OR (ss_type:"les_entreprises") OR (ss_type:"les_parcs") OR (ss_type:"les_quartiers_tribus") OR (ss_type:"les_villes") OR (ss_type:"les_voies") OR (ss_type:"les_ilots") OR (ss_type:"les_iles"))',
    '((ss_type:"les_entreprises") OR (ss_type:"les_voies") OR (ss_type:"les_lieux"))',
    "index_id:index_des_entreprises_uniquement",
  ],
  facet: true,
  "facet.sort": "count",
  "facet.limit": "-1",
  "facet.mincount": "1",
  "facet.missing": "false",
  "facet.field": [
    "im_field_categories",
    "is_field_nom_ville",
    "ss_field_type_business",
  ],
  sort: "sort_field_offre_choisie$field_classement_offre asc, sort_title asc",
  spellcheck: "true",
  wt: "json",
  "json.nl": "map",
  start: "0",
  rows: "3000",
  // if ($business !== "All") "&fq=ss_field_type_business%3A%22".$business."%22&",
}

const defautTransform = data => {
  data.items = []
  data.activeFilters = false
  const items = data.response.docs
    .map(dataItem => {
      var node = JSON.parse(dataItem.ss_node_json)

      forEach(node, function(value, key) {
        if (isArray(value) && isEmpty(value)) {
          node[key] = {
            und: null,
          }
        }
      })
      node.field_reference_rue = dataItem.hasOwnProperty(
        "tm_field_reference_rue$title"
      )
        ? {
            und: [
              {
                nid: {
                  nid: "" + dataItem.is_field_reference_rue,
                  title: dataItem.tm_field_reference_rue$title[0],
                },
              },
            ],
          }
        : { und: null }
      node.field_nom_ville = dataItem.hasOwnProperty("tm_field_nom_ville$title")
        ? {
            und: [
              {
                nid: {
                  nid: "" + dataItem.is_field_nom_ville,
                  title: dataItem.tm_field_nom_ville$title,
                },
              },
            ],
          }
        : { und: null }
      node.field_offre_choisie = dataItem.hasOwnProperty(
        "ss_field_offre_choisie$title"
      )
        ? {
            und: [
              {
                nid: {
                  nid: "" + dataItem.is_field_offre_choisie,
                  title: dataItem.ss_field_offre_choisie$title,
                },
              },
            ],
          }
        : { und: null }
      node.field_quartier = dataItem.hasOwnProperty("tm_field_quartier$title")
        ? {
            und: [
              {
                nid: {
                  nid: "" + dataItem.is_field_quartier,
                  title: dataItem.tm_field_quartier$title,
                },
              },
            ],
          }
        : { und: null }

      node.field_type_de_lieu = dataItem.hasOwnProperty(
        "tm_field_type_de_lieu$name"
      )
        ? {
            und: [
              {
                tid: {
                  tid: "" + dataItem.is_field_type_de_lieu,
                  name: dataItem.tm_field_type_de_lieu$name[0],
                  field_plan_icon:
                    dataItem.tm_field_type_de_lieu$field_plan_icon[0],
                },
              },
            ],
          }
        : { und: null }
      node.url = dataItem.ss_url
      node.path = {
        path: getPath(dataItem.ss_url),
      }
      dataItem.im_field_categories = dataItem.im_field_categories || []

      if (
        dataItem.im_field_categories.length &&
        dataItem.tm_field_categories$name
      ) {
        node.field_categories.und = []
        for (var i = 0; i < dataItem.im_field_categories.length; i++) {
          node.field_categories.und.push({
            tid: {
              tid: "" + dataItem.im_field_categories[i],
              name: dataItem.tm_field_categories$name[i],
            },
          })
        }
      }
      return node
    })
    .filter(item => {
      if (item.type === "les_lieux") {
        const alowed = [
          "icon-shopping-bag",
          "icon-poi_ilots",
          "icon-automobile",
          "icon-poi_parks",
        ]
        return (
          alowed.indexOf(item.field_type_de_lieu.und[0].tid.field_plan_icon) >
          -1
        )
      }
      return true
    })
  const currentIsMap = store.getState().global.isMap
  var lieux = items.filter(item => item.type !== "les_entreprises")
  var entreprises = items.filter(item => item.type === "les_entreprises")
  data.items = currentIsMap ? lieux.concat(entreprises) : entreprises

  const spellcheck = []
  if (data.spellcheck) {
    forEach(data.spellcheck.suggestions, function(value) {
      spellcheck.push(...value.suggestion)
    })
  }
  data.spellcheckSuggestions = uniq(spellcheck)
  return data
}

const addFilterTag = data => {
  data.activeFilters = true
  return data
}

export const setLastListPath = path => ({
  type: SOLR_SET_LAST_LIST_PATH,
  path: path,
})

export const fetchSolr = (word, tid, villenid, parentTid, withoutVille) => {
  withoutVille = withoutVille || false
  const params = { ...defaultParams }
  const searchAround = store.getState().global.searchAround
  const userPosition = store.getState().global.userPosition
  const fq = [...defaultParams.fq]
  const transformResponse = [defautTransform]
  tid = tid || false
  villenid = villenid || false
  parentTid = parentTid || false
  if (word) {
    params.q = word
  }
  if (tid) {
    fq.push('im_field_categories$tid:"' + tid + '"')
  }
  if (villenid) {
    fq.push('is_field_nom_ville$nid:"' + villenid + '"')
  }
  if (parentTid) {
    fq.push('im_field_categories$parents_all:"' + parentTid + '"')
  }
  if ((word && tid) || (word && villenid) || (villenid && !withoutVille)) {
    transformResponse.push(addFilterTag)
  }
  params.fq = fq
  if (searchAround !== false && userPosition !== false) {
    params.sort =
      "geodist(locs_field_position$latlon," +
      userPosition[1] +
      ", " +
      userPosition[0] +
      ") asc, " +
      defaultParams.sort
  }
  return {
    type: FETCH_SOLR_LOAD,
    payload: {
      client: "solR",
      request: {
        url: "select",
        paramsSerializer: params =>
          Qs.stringify(params, { arrayFormat: "repeat" }),
        params: params,
        transformResponse: transformResponse,
      },
      city: villenid,
      categorie: tid,
      categorieParent: parentTid,
      searchPos: userPosition,
    },
  }
}

export const resultSetWord = word => ({
  type: SOLR_SET_WORD,
  word: word,
})

export const solrReset = () => ({
  type: SOLR_RESET,
})

export const solrResetItems = () => ({
  type: SOLR_RESET_ITEMS,
})

export const solrResetFilters = () => ({
  type: SOLR_RESET_FILTERS,
})
