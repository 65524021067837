const colors = {
  transparent: "transparent",
  muted: "#d3d3d3",
  gray: "#A0A9BA",
  gray50: "rgba(160, 169, 186, 0.5)",
  gray30: "rgba(160, 169, 186, 0.3)",
  gray10: "rgba(160, 169, 186, 0.1)",
  grayDark: "#233239",
  grayLight: "#F5F5F5",
  highlight: "rgba(160, 169, 186, 0.5)",
  primary: "#7CAF41",
  primaryDark: "#628a33",
  primaryLight: "#9DCB21",
  secondary: "#ff4400",
  text: "#2F4858",
  textHover: "#406278",
  background: "#ffffff",
  danger: "#e14d46",
  dangerHover: "#f26a3f",
  warning: "#ff8d24",
  warningHover: "#fec722",
  success: "#009F78",
  successHover: "#1fbe51",
  footer: "#3c3c3c",
  pink: "#F761A1",
  purple: "#8C1BAB",
  purpleGradiant: "#F761A1",
  purpleHover: "#710290",
  golden: "#FBAB00",
  goldenHover: "#F7CE68",
  lieux: "#3b9dcb",
  blue: "#1977a0",
  blueGradiant: "#3194c2",
  blueHover: "#3194c2",
  gradiantPrimary: "linear-gradient(90deg, #9DCB21 0%, #7CAF41 100%);",
  gradiantText: "linear-gradient(90deg, #2F4858 0%, #406278 100%);",
  gradiantPurple: "linear-gradient(90deg, #F761A1 0%, #8C1BAB 100%);",
  gradiantGold: "linear-gradient(90deg, #FBAB00 0%, #F7CE68 100%);",
  gradiantPlatinum: "linear-gradient(90deg, #001621 0%, #3D5068 100%);",
  gradiantPurpleButton:
    "linear-gradient(90deg, #F761A1 , #8C1BAB 50%,#F761A1);",
  gradiantGoldButton: "linear-gradient(90deg, #FBAB00, #F7CE68 50%,#FBAB00);",
  gradiantPrimaryButton:
    "linear-gradient(90deg, #9DCB21, #7CAF41 50%,#9DCB21);",
}

export default colors
