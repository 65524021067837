import {
  SET_LAST_AD_DATE,
} from '../actions/persistAction';

// import { PURGE } from 'redux-persist';

const initialState = {
  lastAdDate: 1,
};

export default function resultReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LAST_AD_DATE:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        lastAdDate: action.adDate
      };
    // case PURGE:
    //   return state;// Return the initial state of this reducer to 'reset' the app
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}