import isEmpty from "lodash/isEmpty"
import colors from "../theme/colors"
import { useEffect, useRef } from "react"

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const getPath = url => {
  url = url || ""
  return url.replace(
    /^((?:https?:)|(?:public?:))?(?:\/\/?)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/g,
    ""
  )
}

export const getErrorMessage = response => {
  const drupalMessage = {
    "Not Acceptable : Account is temporarily blocked.":
      "Le compte est temporairement bloqué, veuiller contacter l'administrateur.",
    "Unauthorized : Wrong username or password.":
      "Mot de passe ou nom d'utilisateur erroné.",
    "Wrong username or password.": "Mot de passe ou nom d'utilisateur erroné.",
  }
  var errorMessage = ""
  if (response.data.form_errors) {
    Object.keys(response.data.form_errors).map(key => {
      var line = response.data.form_errors[key].replace(/<[^>]*>?/gm, "")
      line = drupalMessage[line] ? drupalMessage[line] : line
      errorMessage += line.replace(/<[^>]*>?/gm, "") + "\n"
      return ""
    })
  } else {
    if (typeof response.data !== "string") {
      response.data.map(errorline => {
        var line = drupalMessage[errorline]
          ? drupalMessage[errorline]
          : errorline
        errorMessage += line.replace(/<[^>]*>?/gm, "") + "\n"
        return ""
      })
    } else {
      var line = drupalMessage[response.data]
        ? drupalMessage[line]
        : response.data
      errorMessage += line.replace(/<[^>]*>?/gm, "") + "\n"
    }
  }
  return errorMessage
    .replaceAll("Not Acceptable : ", "")
    .replaceAll("Ã©", "é")
    .replaceAll("Ã", "à")
}

export const getValue = (node, fieldName, type, defaultValue) => {
  type = type || false
  if (typeof defaultValue === "undefined") {
    defaultValue = false
  }
  if (typeof node !== "object") {
    return defaultValue
  }
  if (!node.hasOwnProperty(fieldName)) {
    return defaultValue
  }
  if (node[fieldName] === null) {
    return defaultValue
  }
  if (type === false) {
    return node[fieldName].und === null || node[fieldName].length === 0
      ? defaultValue
      : node[fieldName].und[0]
  }
  if (typeof type === "string") {
    if (type === "Raw") {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und
    }
    if (type === "Phone") {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : formatPhone(node[fieldName].und[0])
    }
    const types = type.split(".")
    if (types.length === 1) {
      return node?.[fieldName].und === null || node?.[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]]
    }
    if (types.length === 2) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]] === null ||
          node[fieldName].und[0][types[0]].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]]
    }
    if (types.length === 3) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]] === null ||
          node[fieldName].und[0][types[0]].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]] === null ||
          node[fieldName].und[0][types[0]][types[1]].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]][types[2]]
    }
    if (types.length === 4) {
      return node[fieldName].und === null || node[fieldName].length === 0
        ? defaultValue
        : node[fieldName].und[0][types[0]][types[1]][types[2]][types[3]]
    }
  }
}

export const isMembreGratuit = node => {
  return parseInt(getValue(node, "field_offre_choisie", "nid.nid", 48)) === 48
}

export const getNodePath = (node, isMap) => {
  const path = node.path.path
  return isMap ? "/entreprises/" + path.split("/").slice(-1)[0] : path
}

export const renameImage = (imgLink, defaultImg, style) => {
  imgLink = imgLink || false
  defaultImg = defaultImg || false
  style = style || false
  const path = style ? "/files/styles/" + style + "/public/" : "/files/"
  if (!imgLink) {
    return defaultImg
  }
  return imgLink.replace("public://", path)
}

export const formatPhone = number => {
  number = number || false
  if (typeof number !== "object") {
    return false
  }
  if (number.number === null || number.number === "") {
    return false
  }
  number.country_codes = number.country_codes || "NC"
  const countries = {
    NC: {
      phone: "687",
    },
    FR: {
      phone: "33",
    },
    PF: {
      phone: "689",
    },
    VU: {
      phone: "678",
    },
  }
  const country = countries[number.country_codes.toUpperCase()]
  if (typeof country !== "object") {
    return false
  }
  return {
    link: "tel:+" + country.phone + number.number,
    text:
      "(+" + country.phone + ") " + number.number.match(/.{1,2}/g).join(" "),
  }
}

export const youTubeGetID = url => {
  var ID = ""
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/)
  if (url[3] !== undefined) {
    ID = url[3]
  } else {
    ID = url
  }
  return ID
}

export const getValidUrl = (url = "") => {
  let newUrl =
    typeof window !== "undefined" ? window.decodeURIComponent(url) : url
  newUrl = newUrl.trim().replace(/\s/g, "")

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }

  return newUrl
}

export const getColorName = offre => {
  var colorname

  switch (offre) {
    case "47":
      colorname = "purple"
      break
    case "180":
      colorname = "golden"
      break
    case "48":
      colorname = "primary"
      break
    default:
      colorname = "primary"
  }
  return colorname
}
export const getIconName = offre => {
  var colorname

  switch (offre) {
    case "47":
      colorname = "premium"
      break
    case "180":
      colorname = "gold"
      break
    case "48":
      colorname = "gratuit"
      break
    default:
      colorname = "lieux"
  }
  return colorname
}

export const isOpenNow = hours => {
  hours = hours || false
  if (!hours || isEmpty(hours)) {
    return false
  }
  const nowNew = new Date()
  const nowDay = nowNew.getDay()
  const nowMin =
    nowNew.getMinutes() > 9 ? nowNew.getMinutes() : "0" + nowNew.getMinutes()
  const nowHourMin = nowNew.getHours() + "" + nowMin
  const open = hours.find(
    o =>
      parseInt(o.day) === parseInt(nowDay) &&
      parseInt(o.starthours) <= parseInt(nowHourMin) &&
      parseInt(o.endhours) >= parseInt(nowHourMin)
  )
  return open ? true : false
}

export const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getNodeTitle = node => {
  const preTitle =
    getValue(node, "field_prefixe_voie", "value", false) &&
    node.type === "les_voies"
      ? getValue(node, "field_prefixe_voie", "value", "") + " "
      : ""
  const title = capitalize(
    unescape(preTitle + node.title)
      .replace("&#039;", "'")
      .replace("' ", "'")
  )
  return title
}

export const createMarker = (node, isCurrent) => {
  isCurrent = isCurrent || false
  return {
    center: [
      getValue(node, "field_position", "lon"),
      getValue(node, "field_position", "lat"),
    ],
    color: colors[getColorName(node.offre)],
    icon:
      node.type === "les_lieux"
        ? getValue(node, "field_type_de_lieu", "tid.field_plan_icon", "")
        : getIconName(getValue(node, "field_offre_choisie", "nid.nid", "")),
    isCurrent: isCurrent,
    nid: node.nid,
    title: getNodeTitle(node),
    path: getNodePath(node, true),
    node: node,
    type: node.type,
  }
}

export const createCategorieBreadCrumb = categorie => {
  const crumbs = []
  if (categorie) {
    crumbs.push({
      path: categorie.url,
      title: categorie.name,
    })
    if (categorie.parentCategorie) {
      crumbs.push({
        path: categorie.parentCategorie.url,
        title: categorie.parentCategorie.name,
      })
      if (categorie.parentCategorie.parentCategorie) {
        crumbs.push({
          path: categorie.parentCategorie.parentCategorie.url,
          title: categorie.parentCategorie.parentCategorie.name,
        })
        if (categorie.parentCategorie.parentCategorie.parentCategorie) {
          crumbs.push({
            path: categorie.parentCategorie.parentCategorie.parentCategorie.url,
            title:
              categorie.parentCategorie.parentCategorie.parentCategorie.name,
          })
        }
      }
    }
  }
  return crumbs.reverse()
}

export const getUserPosition = cb => {
  if (typeof window !== "undefined" && typeof cb === "function") {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(cb)
    }
  }
}
/*
 * Formatage des distance
 */
export const formatDistance = distance => {
  if (distance < 1000) {
    return Math.round(distance) + " m"
  } else {
    var formatDistance = distance / 1000
    formatDistance = formatDistance.toString()
    var n = formatDistance.indexOf(".")
    n = n + 2
    formatDistance = formatDistance.substring(0, n)
    formatDistance = formatDistance.replace(".", ",")
    return formatDistance + " Km"
  }
}
/*
 * Calcul de la distande depuis la position actuelle
 */
export const getDistance = (pos1, pos2) => {
  //function from http://www.geodatasource.com/developers/javascript
  const radlat1 = (Math.PI * pos1.lat) / 180
  const radlat2 = (Math.PI * pos2.lat) / 180
  const theta = pos1.lon - pos2.lon
  const radtheta = (Math.PI * theta) / 180
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist = dist * 1.609344
  dist = dist * 1000
  return dist
}

/*
 * Calcul de la distande depuis la position actuelle
 */
export const getDistanceFromNode = (userPosition, node) => {
  return getDistance(
    { lat: userPosition[1], lon: userPosition[0] },
    {
      lat: parseFloat(getValue(node, "field_position", "lat")),
      lon: parseFloat(getValue(node, "field_position", "lon")),
    }
  )
}

export const sendEvent = (categorie, action, name, value) => {
  if (typeof window !== "undefined") {
    if (typeof window._paq !== "undefined") {
      window._paq.push(["trackEvent", categorie, action, name, value])
    }
  }
}
